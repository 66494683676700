import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IPasswordSettings } from '../../../../../types/passwordSetting.types';
import useAuth from '../../../../../hooks/useAuth';
import useSpinner from '../../../../../hooks/useSpinner';

const PasswordSettings = () => {
  const { updatePassword } = useAuth();
  const spinner = useSpinner();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IPasswordSettings>({
    defaultValues: {},
  });
  const [message, setMessage] = useState('');

  const onSubmit = (values: IPasswordSettings) => {
    spinner.open();
    updatePassword(values.old_password, values.new_password)
      .then(() => {
        spinner.close();
      })
      .catch(() => spinner.close());
  };

  return (
    <section>
      <div className="content_outer">
        <div id="edit-profile">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="profile_form"
            encType="multipart/form-data"
          >
            <div className="form-group-wrap--name">
              <div className="form-group">
                <label className="control-label">Current Password</label>
                <input
                  className="form-control"
                  type="password"
                  autoComplete="current-password"
                  {...register('old_password', {
                    required: 'Current password is required',
                  })}
                />
                {errors.old_password?.message && (
                  <span className="error_message">{errors.old_password?.message}</span>
                )}
              </div>
              <div className="form-group">
                <label className="control-label">New Password</label>
                <input
                  className="form-control"
                  type="password"
                  autoComplete="new-password"
                  {...register('new_password', { required: 'New password is required' })}
                />
                {errors.new_password?.message && (
                  <span className="error_message">{errors.new_password?.message}</span>
                )}
              </div>
            </div>
            <div className="button_outer">
              <button className="btn btn-primary" type="submit">
                Update Password
              </button>
            </div>
            <div className="button_outer">{message}</div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default PasswordSettings;
